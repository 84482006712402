<template>
  <div class="col-lg-7 col-xl-3">
    <!-- Simple card -->
    <b-card style="border-radius: 13px">
      <router-link
        :to="`/award/awardCandidateCategorie/${categorie.categorie.id}/${rang}`"
        style="color: #303030"
      >
        <div>
          <img
            class="card-img"
            :src="categorie.categorie.imagesMap.imageportrait.url"
            alt="Card image"
            style="object-fit: cover; opacity: 0.9"
          />
        </div>

        <b-card-title>
          <h4
            style="
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              text-align: center;
            "
            :title="categorie.categorie.name"
          >
            {{ categorie.categorie.name }}
          </h4>
        </b-card-title>
        <b-card-text> </b-card-text>
      </router-link>
      <div
        style="display: flex; align-items: center; justify-content: center"
        v-if="categorie.categorie.award.isPublished == false"
      >
        <b-button class="c2play-primary">
          <b-dropdown
            class="card-drop"
            variant="white"
            menu-class="dropdown-menu-end"
            right
            toggle-class="p-0"
          >
            <template v-slot:button-content>
              <span style="color: #fff"
                ><i class="mdi mdi-dots-horizontal font-size-18"></i
              ></span>
            </template>
            <b-dropdown-item @click="confirm()">{{
              $t("pages.videos.actions.supprimer")
            }}</b-dropdown-item>
          </b-dropdown>
        </b-button>
      </div>
    </b-card>
  </div>

  <!-- <b-modal v-model="addModal" id="modal-xl" size="xl" title="Nouvelle catégorie" title-class="font-18" hide-footer>
            <div class="card-body">
                <b-alert v-model="isAuthError" variant="danger" class="mt-1" dismissible>
                    {{ authError }}
                </b-alert>
                <form>
                    <div class="row">
                        <div class="form-group col-lg-6 mb-3">
                            <label for="projectname" class="col-form-label">Nom</label>
                            <div class="col-lg-12">
                                <input id="projectname" v-model="awardCategorie.name" name="projectname" type="text"
                                    class="form-control" placeholder="Entrez le nom de la catégorie" />
                            </div>
                        </div>

                        <div class="form-group col-lg-6 mb-3">
                            <label class="col-form-label">Prix du vote (XAF)</label>
                            <div class="col-lg-12" v-if="award.free == false">
                                <input id="projectname" v-model="awardCategorie.votePrice" name="projectname" type="text"
                                    class="form-control" placeholder="Entrez le prix du vote" />
                            </div>
                            <div class="col-lg-12" v-else>
                                00 XAF
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="form-group col-lg-4">
                            <label for="projectbudget" class="col-form-label">Image Portrait</label>
                            <div class="col-lg-12">
                                <input id="projectbudget" name="projectbudget" type="file"
                                    @change="imageFileSelected($event)" class="form-control" accept=".png, .jpeg, .jpg" />
                            </div>
                        </div>

                        <div class="form-group col-lg-4">
                            <label for="projectbudget" class="col-form-label">Image plateau (Paysage)</label>
                            <div class="col-lg-12">
                                <input id="projectbudget" name="projectbudget" type="file"
                                    @change="imagePFileSelected($event)" class="form-control" accept=".png, .jpeg, .jpg" />
                            </div>
                        </div>


                        <div class="form-group col-lg-4">
                            <label for="projectbudget" class="col-form-label">Vidéo de couverture</label>
                            <div class="col-lg-12">
                                <input id="projectbudget" name="projectbudget" type="file" @change="videoSelect($event)"
                                    class="form-control" accept=".MKV, .AVI, .MP4, MOV" />
                            </div>
                        </div>
                    </div>

                    <div class="form-group row mb-4">
                        <label for="projectbudget" class="col-form-label">Description</label>

                        <div class="col-lg-12">
                            <textarea id="projectdesc" class="form-control" v-model="awardCategorie.description" rows="3"
                                :placeholder="$t('pages.programme.formulaire.description.placeholder')
                                    "></textarea>
                        </div>
                    </div>
                </form>
                <div class="row justify-content-center">
                    <div style="margin-top: 10px; display: flex;align-items: center;justify-content: center;">
                        <button type="submit" class="btn c2play-primary" @click="createAwardCategorie">
                            {{ $t("addButton") }}
                        </button>
                    </div>
                </div>
            </div>
        </b-modal> 
    -->
</template>

<script>
import Swal from "sweetalert2";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
export default {
  data() {
    return {
      time: "",
    };
  },
  props: {
    categorie: {
      type: Object,
      required: true,
    },
    rang: Number,
  },
  methods: {
    confirm() {
      Swal.fire({
        title: "Etes vous sùre?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Annuler",
        confirmButtonText: "Supprimer",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.deleteCategorieInAward();
        }
      });
    },

    deleteCategorieInAward() {
      Api.put(
        `/voting/rest/awards/remove-categorie/${this.$route.params.id}?categorie_id=${this.categorie.categorie.id}`
      )
        .then((response) => {
          console.log(response.data);
          Swal.fire("Deleted!", "La catégorie a bien été supprimé", "success");
          // location.reload();
        })
        .catch((error) => {
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {},
};
</script>

<style>
.card-img {
  object-fit: cover;
  height: 200px;
  width: 100%;
  border-radius: 9px;
  margin-bottom: 20px;
}
</style>
